import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
	name: "",
	email: "",
	message: "",
};
export const Contact = (props) => {
	const [{ name, email, message }, setState] = useState(initialState);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const clearState = () => setState({ ...initialState });

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(name, email, message);

		emailjs
			.sendForm(
				"YOUR_SERVICE_ID",
				"YOUR_TEMPLATE_ID",
				e.target,
				"YOUR_PUBLIC_KEY"
			)
			.then(
				(result) => {
					console.log(result.text);
					clearState();
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	return (
		<div id="contact">
			<div className="container">
				<div className="col-md-8">
					<div className="row">
						<div className="section-title">
							<h2>Ponte en contacto</h2>
							<p>
								Por favor complete el formulario a continuación
								para enviarnos un correo electrónico y le
								responderemos lo antes posible.
							</p>
						</div>
						<form
							name="sentMessage"
							validate
							onSubmit={handleSubmit}
						>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="text"
											id="name"
											name="name"
											className="form-control"
											placeholder="Name"
											required
											onChange={handleChange}
										/>
										<p className="help-block text-danger"></p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="email"
											id="email"
											name="email"
											className="form-control"
											placeholder="Email"
											required
											onChange={handleChange}
										/>
										<p className="help-block text-danger"></p>
									</div>
								</div>
							</div>
							<div className="form-group">
								<textarea
									name="message"
									id="message"
									className="form-control"
									rows="4"
									placeholder="Message"
									required
									onChange={handleChange}
								></textarea>
								<p className="help-block text-danger"></p>
							</div>
							<div id="success"></div>
							<button
								type="submit"
								className="btn btn-custom btn-lg"
							>
								Enviar Mensaje
							</button>
						</form>
					</div>
				</div>
				<div className="col-md-3 col-md-offset-1 contact-info">
					<div className="contact-item">
						<h3>Información de contacto</h3>
						<p>
							<span>
								<i className="fa fa-map-marker"></i> Address
							</span>
							{props.data ? props.data.address : "loading"}
						</p>
					</div>
					<div className="contact-item">
						<p>
							<span>
								<i className="fa fa-phone"></i> Telefono
							</span>{" "}
							{props.data ? props.data.phone : "loading"}
						</p>
					</div>
					<div className="contact-item">
						<p>
							<span>
								<i className="fa fa-envelope-o"></i> Correo
							</span>{" "}
							{props.data ? props.data.email : "loading"}
						</p>
					</div>
				</div>
				<div className="col-md-12">
					<div className="row">
						<div className="social">
							<ul>
								<li>
									<a
										href={
											props.data
												? props.data.facebook
												: "/"
										}
									>
										<i className="fa fa-facebook"></i>
									</a>
								</li>
								<li>
									<a
										href={
											props.data
												? props.data.twitter
												: "/"
										}
									>
										<i className="fa fa-twitter"></i>
									</a>
								</li>
								<li>
									<a
										href={
											props.data
												? props.data.youtube
												: "/"
										}
									>
										<i className="fa fa-youtube"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
