import React from "react";

export const Pricing = ({ data }) => {
	console.log("pricing, ", data);
	return (
		<div id="pricing" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>{data?.title}</h2>
					<p>{data?.paragraph}</p>
				</div>
				<div className="row justify-content-center">
					<div className="w-100">
						<div className="pricing-box">
							<div className="price-figure">
								<span className="price-number">$4.99</span>
								<span className="price-period">/mes</span>
							</div>
							<ul
								className="list-unstyled"
								style={{ marginBottom: "3rem" }}
							>
								{data?.features
									? data.features.map((feature, index) => (
											<li
												key={`feature-${index}`}
												className="pricing-feature"
											>
												<i className="fa fa-check"></i>{" "}
												{feature}
											</li>
									  ))
									: "loading"}
							</ul>
							<div className="mt-4">
								<a
									className="btn btn-custom btn-lg page-scroll"
									href="https://ryvalfit.com"
								>
									Regístrate Ahora
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
