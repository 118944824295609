import React from "react";

export const Features = (props) => {
	/*
		/*<div id="features" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>Funcionalidades</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit
						duis sed dapibus leonec.
					</p>
				</div>
				<div className="row">
					{props.data
						? props.data.map((d, i) => (
								<div
									key={`${d.title}-${i}`}
									className="col-md-4"
								>
									{" "}
									<i className={d.icon}></i>
									<div className="service-desc">
										<h3>{d.title}</h3>
										<p>{d.text}</p>
									</div>
								</div>
						  ))
						: "Loading..."}
				</div>
			</div>
						</div>*/
	return (
		<div id="features" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>Funcionalidades</h2>
				</div>
				<div
					id="myCarousel"
					className="carousel slide"
					data-ride="carousel"
				>
					<ol className="carousel-indicators">
						{props.data
							? props.data.map((d, i) => (
									<li
										data-target="#myCarousel"
										data-slide-to={i}
										className={i === 0 ? "active" : ""}
									></li>
							  ))
							: "Loading..."}
					</ol>

					<div className="carousel-inner" style={{ height: "600px" }}>
						{props.data
							? props.data.map((d, i) => (
									<>
										<div
											className={`item ${
												i === 0 && "active"
											}`}
										>
											<h3 id="carousel-title">
												{d.title}
											</h3>
											<img
												src={`img/features/${d.image}`}
												style={{
													width: "100%",
												}}
												alt=""
											/>
											<div className="carousel-caption">
												<p>{d.text}</p>
											</div>
										</div>
									</>
							  ))
							: "Loading..."}
					</div>

					<a
						className="left carousel-control"
						href="#myCarousel"
						data-slide="prev"
					>
						<i className="fa fa-chevron-left"></i>
						<span className="sr-only">Previous</span>
					</a>
					<a
						className="right carousel-control"
						href="#myCarousel"
						data-slide="next"
					>
						<i className="fa fa-chevron-right"></i>

						<span className="sr-only">Next</span>
					</a>
				</div>
			</div>
		</div>
	);
};
